import { BaseType } from "./util/BaseType";
import { Expose } from "class-transformer";
import { ProduktModel } from "./ProduktModel";
import { api } from '@/util/api';
import { catApi } from '@/util/logging';

export class BuchBestandModel extends BaseType {

    @Expose({ name: "uid" })
    uid = '';

    @Expose({ name: "buchUID" })
    buchUID = '';

    @Expose({ name: "filialeUID" })
    filialeUID = '';

    @Expose({ name: "f_num" })
    f_num = -1;

    @Expose({ name: "menge" })
    menge = -1;

    // ====

    static read = async (produkt: ProduktModel): Promise<BuchBestandModel[]> => {

        catApi.debug("querying: " + produkt.url);
        if (produkt === null || produkt === undefined || produkt.url == "") {
            catApi.info("  return: [] (because " + produkt + ")");
            return [];
        }

        try {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const path = `v1${produkt.url}/bestand`
            const response = await api.get<BuchBestandModel[]>(
                path
            );
            catApi.debug(`response: /${path} -> ${JSON.stringify(response)}`);

            const ret = BaseType.convertArray(BuchBestandModel, response.data)
            catApi.debug(`response: /${path} -> ${JSON.stringify(ret)}`);
            return ret
            //
        } catch (e) {

            const err = new Error(JSON.stringify(e))
            catApi.error("caught Error: " + err.message, err);
            return Promise.reject(err)
        }
    }
}
