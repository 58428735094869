










































































































import { BuchBestandModel } from "@/models/BuchBestandModel";
import { BuchBewegungModel } from "@/models/BuchBewegungModel";
import { FilialeModel } from "@/models/FilialeModel";
import { ProduktModel } from "@/models/ProduktModel";
import Vue from "vue";
import { catApi, catVue } from "@/util/logging";
import { mapState } from "vuex";
import { api } from "@/util/api";
import Datum from "@/util/datum";
import { formatDistanceToNow } from "date-fns";
import { de } from "date-fns/locale";

const ranges = [
  //     { seq: 1, lbl: BuchBewegungModel.PERIODE_TAGE },
  //     { seq: 2, lbl: BuchBewegungModel.PERIODE_WOCHEN },
  { seq: 3, lbl: BuchBewegungModel.PERIODE_MONATE },
  { seq: 4, lbl: BuchBewegungModel.PERIODE_QUARTALE },
  { seq: 5, lbl: BuchBewegungModel.PERIODE_JAHRE },
];

export default Vue.extend({
  // Component name for reference
  name: "BuchAnzeige",

  created() {
    catVue.info(`${this.$options.name}:: CREATED... `);
  },
  mounted() {
    catVue.info(
      `${this.$options.name}:: MOUNTED... props.produkt -> ${this.produkt.url}`
    );

    this.load(1);
  },
  data: () => ({
    perioden: [] as BuchBewegungModel[],
    bestaende: [] as BuchBestandModel[],
    loading: 0,
    lieferungen: null as Record<string, string> | null,
  }),
  props: {
    produkt: Object,
  },
  computed: {
    filialen(): FilialeModel[] {
      catVue.info("Called. This.filialenAktiv = " + this.filialenAktiv);
      return this.filialenAktiv;
    },
    ...mapState("auth", ["theFiliale", "filialenAktiv"]),
  },
  methods: {
    selectFiliale(fil: FilialeModel): void {
      this.$store.commit("auth/setFiliale", fil);
    },
    filClass(fil: FilialeModel): string {
      let ret = fil.css + " fil-" + fil.f_kurz;
      if (this.theFiliale.uid === fil.uid) ret += " fil-selected";
      return ret;
    },
    getBestand(fil: FilialeModel): number | string {
      if (
        undefined === this.bestaende[fil.f_num] ||
        0 == this.bestaende[fil.f_num].menge
      )
        return "-";
      return this.bestaende[fil.f_num].menge;
    },

    async load(what: number) {
      // what -> 1: Lade Bestände
      //
      if (what == 1) {
        this.loading++;
        this.bestaende.length = 0;
        BuchBestandModel.read(this.produkt)
          .then((bestaende) => {
            bestaende.forEach(
              (bestand) => (this.bestaende[bestand.f_num] = bestand)
            );
            this.$nextTick(() => this.loading--);
          })
          .catch((error) => {
            catVue.info("Error getting Bestaende: " + error);
          });
      }
      // … und in jedem Fall: Lade Bewegungen für Filiale
      //
      this.perioden = new Array<BuchBewegungModel>();

      ranges.forEach((el) => {
        this.loading++;
        BuchBewegungModel.read(this.produkt, this.theFiliale.f_num, el.lbl)
          .then((bestaende: BuchBewegungModel[]) => {
            catVue.info("Jahre -> " + JSON.stringify(bestaende));
            for (const b of bestaende) {
              b.uid = b.uid.replaceAll(el.lbl, "" + el.seq + ":" + el.lbl);
              b.insertInto(this.perioden);
            }
            this.loading--;
          })
          .catch((error) => {
            catVue.info("Error getting Bewegungen: " + error);
          });
      });

      // ... schießlich: Erster / Letzter Wareneingang
      //
      this.loading++;
      this.lieferungen = null;
      const url = `/v1${this.produkt.url}/lieferungen?filiale=${this.theFiliale.f_num}`;
      try {
        const response = await api.get(url);
        catApi.info(`response: ${url} -> ${JSON.stringify(response)}`);
        if ("data" in response) {
          const r = response.data as Record<string, string>;
          if ("uid" in r && r.uid !== "-") {
            catApi.info(`UID: ${r.uid}`);
            const min = Datum.parse(r.min),
              max = Datum.parse(r.max);

            catApi.info(`Min: ${r.min}`);
            if (min !== null) {
              r.minText = formatDistanceToNow(min, {
                addSuffix: true,
                locale: de,
              });
              catVue.info(`Min: ${r.minText}`);
            }

            catApi.info(`Max: ${r.max}`);
            if (max !== null) {
              r.maxText = Datum.p5sdDD(r.max, "-");
              catVue.info(`Min: ${r.maxText}`);
            }

            this.lieferungen = r;
          }
        }
      } catch (err) {
        catApi.info("API Error/ Exception: " + err);
      } finally {
        this.loading--;
      }
    },
    /* empty */
  },
  watch: {
    produkt(to: ProduktModel, from: ProduktModel): void {
      catVue.info("produkt change ... " + from + "-->" + to);
      this.load(1);
    },
    theFiliale(to: FilialeModel, from: FilialeModel): void {
      catVue.info("filiale change ... " + from + "-->" + to);
      this.load(0);
    },
  },
});
